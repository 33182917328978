export class LoaderConfig {
  backgroundColor: string;
  position: string;
  top: number | string;
  left: number | string;
  width: number | string;
  height: number | string;
  maxWidth: number | string;
  maxHeight: number | string;
  innerBgColor: string;
  outerBgColor: string;
  spinnerColor: string;
  border: string;
  logoColor: string;
  textColor: string;
  constructor(wrapper?: {
    backgroundColor?: string;
    position?: string;
    top?: number | string;
    left?: number | string;
    width?: number | string;
    height?: number | string;
    maxWidth?: number | string;
    maxHeight?: number | string;
    innerBgColor?: string;
    outerBgColor?: string;
    spinnerColor?: string;
    logoColor?: string;
    border?: string;
    textColor?: string;
  }) {
    this.backgroundColor =
      wrapper?.backgroundColor || 'var(--ds-overlay-background)';
    this.position = wrapper?.position || 'fixed';
    this.top = wrapper?.top || 0;
    this.left = wrapper?.left || 0;
    this.width = wrapper?.width || '100vw';
    this.height = wrapper?.height || '100vh';
    this.maxWidth = wrapper?.maxWidth || '100%';
    this.maxHeight = wrapper?.maxHeight || '100%';

    this.innerBgColor = wrapper?.innerBgColor || 'var(--icons-brand-inverted)';
    this.outerBgColor = wrapper?.outerBgColor || 'var(--neutral-85)';
    this.spinnerColor =
      wrapper?.spinnerColor || 'var(--ds-color-ui-brand-secondary)';

    this.logoColor = wrapper?.logoColor || 'var(--ds-color-ui-brand-primary)';
    this.border = wrapper?.border || '3px solid var(--neutral-85)';
    this.textColor = wrapper?.textColor || 'var(--text-brand-inverted)';
  }
}
